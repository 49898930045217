import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.headers['X-CSRF-TOKEN'] = $('meta[name=csrf-token]').attr('content')
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
Vue.use(Vuex)

import notifyModule from '@/entrypoints/notify_module.js'
import searchModule from 'javascripts/common/store/search_module'

const responseSuccess = 'success'
const responseFailed = 'failed'
const requestPath = '/api/applicants/'
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    searchModule
  },
  state: {
    showModal: false,
    applicantLists: [],
    Object: {}, //ModalWindowを開けたときにデータがセットされる
    statusGroups: {},
    formOptions: {},
    globalUserFlag: false,
    currentTab: '',
    hasContract: Boolean
  },
  mutations: {
    setupStatusGroups(state, groups) {
      state.statusGroups = groups
    },
    // グローバルユーザーフラグを設定する
    setGlobalUserFlag(state, globalUserFlag) {
      state.globalUserFlag = globalUserFlag;
    },
    setupFormOptions(state, options) {
      state.formOptions = options
    },
    updateModal(state, modalFlag) {
      state.showModal = modalFlag
    },
    setApplicant(state, applicant) {
      state.Object = applicant
    },
    setApplicantLists(state, applicants) {
      state.applicantLists = applicants
    },
    setCurrentTab(state, tabName) {
      state.currentTab = tabName
    },
    setupHasContract(state, hasContract) {
      state.hasContract = hasContract
    },
    dropApplicant(state, applicant) {
      state.Object = {}
    },
    dropApplicantLists(state, applicants) {
      state.Object = {}
      state.applicantLists = applicants
    },
    commitApplicant(state, applicant) {
      const objectIndex = state.applicantLists.findIndex(element => element.code === applicant.code)
      state.Object = applicant
      if (objectIndex == -1) {//新規の場合は末尾に追加
        state.applicantLists.push(applicant)
      } else {
        state.applicantLists.splice(objectIndex, 1, applicant)
      }
    },
    commitNextStepApplicant(state, applicant) {
      const objectIndex = state.applicantLists.findIndex(element => element.code === applicant.code)
      if (objectIndex != -1) {
        state.applicantLists.splice(objectIndex, 1)
      }
    }
  },
  actions: {
    // 選考を更新
    async postApplicant({ commit }, { applicant, type }) {
      // リクエストパラメーター
      const request_parameter = {
        applicants_api: {
          applicant,
          global_user_flag: this.state.globalUserFlag
        }
      };
      // created_atに値が入っている場合は更新処理なので、codeを設定する
      if (applicant.created_at) request_parameter.applicants_api.code = applicant.code;

      try {
        const response = await axios.post(`${requestPath}save`, request_parameter);

        commit('commitApplicant', response.data);

        let response_message = '詳細情報を';
        if (type == 'message') { response_message = 'メッセージを'; }
        notifyModule.successModalAlert(response_message + '更新しました');

        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorModalAlert(error);
        return responseFailed;
      }
    },
    // サブステータスを更新
    async updateSubStatus({ commit }, { code: applicant_code, sub_status, close_reason }) {
      try {
        const response = await axios.post(
          `${requestPath}update_sub_status`,
          {
            applicants_api: {
              code: applicant_code,
              sub_status,
              close_reason,
              global_user_flag: this.state.globalUserFlag
            }
          }
        );

        commit('commitApplicant', response.data);
        notifyModule.successAlert('進行状況を更新しました');
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorAlert(error);
        return responseFailed;
      }
    },
    // ステータスを更新
    async updateStatus({ commit }, { code: applicant_code, status }) {
      try {
        const response = await axios.post(
          `${requestPath}update_status`,
          {
            applicants_api: {
              code: applicant_code,
              status,
              global_user_flag: this.state.globalUserFlag
            }
          }
        );

        commit('commitNextStepApplicant', response.data);
        notifyModule.successAlert('ステータスを更新しました');
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorAlert(error);
        return responseFailed;
      }
    },
    // 閲覧履歴を更新
    async updateViewingHistory({ commit }, { code: applicant_code, viewing_type }) {
      try {
        const response = await axios.post(
          `${requestPath}update_viewing_history`,
          {
            applicants_api: {
              code: applicant_code,
              viewing_type,
              global_user_flag: this.state.globalUserFlag
            }
          }
        );

        commit('commitApplicant', response.data);

        // 閲覧された時にバックグラウンドで処理するため成功はユーザーに通知しない
        return responseSuccess;
      }
      catch (_e) {
        // 閲覧された時にバックグラウンドで処理するため失敗はユーザーに通知しない
        return responseFailed;
      }
    },
    // 採用メモ更新
    async updateInternalMemo({ commit }, { code: applicant_code, internal_memo }) {
      try {
        const response = await axios.post(
          `${requestPath}update_internal_memo`,
          {
            code: applicant_code,
            internal_memo,
            global_user_flag: this.state.globalUserFlag
          }
        );
        commit('commitApplicant', response.data);
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorAlert(error);
        return responseFailed;
      }
    },
    // ファイルを添付
    async attachFile({ commit }, { code: applicant_code, file }) {
      // form-dataを作成
      const fd = new FormData;
      fd.append('file', file);
      fd.append('global_user_flag', this.state.globalUserFlag);

      try {
        const response = await axios.post(
          `${requestPath}${applicant_code}/attachment_file`, fd
        );
        commit('commitApplicant', response.data);
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorModalAlert(error);
        return responseFailed;
      }
    },
    // 添付ファイルを削除
    async removeAttachmentFile({ commit }, { code: applicant_code, filename }) {
      try {
        const response = await axios.delete(
          `${requestPath}${applicant_code}/attachment_file/${filename}?global_user_flag=${this.state.globalUserFlag}`
        );
        commit('commitApplicant', response.data);
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorModalAlert(error);
        return responseFailed;
      }
    },
    // メッセージ更新
    async updateMessage({ commit }, { code: applicant_code, memo, lock_version }) {
      try {
        const response = await axios.post(
          `${requestPath}update_message`,
          {
            applicants_api: {
              code: applicant_code,
              memo,
              lock_version,
              global_user_flag: this.state.globalUserFlag
            }
          }
        );
        commit('commitApplicant', response.data);
        // メッセージが更新されて送信準備状態になると、メッセージモーダル内にアラートを表示するため、ここではアラート表示処理を行わない
        return responseSuccess;
      }
      catch (error) {
        notifyModule.errorModalAlert(error);
        return responseFailed;
      }
    }
  },
  getters: {
    getModalStatus(state, getters, rootState) {
      return state.showModal
    },
    getFormOptions: (state) => {
      return state.formOptions
    },
    statusGroups: (state) => {
      return state.statusGroups
    },
    filtering: (state) => (search_q) => {
      let filteredObj = state.applicantLists.filter(row => {
        let filterKey = row.serial
        return filterKey.includes(search_q)
      })
      return filteredObj
    }
  },

  strict: debug
})
import Vue from 'vue/dist/vue.esm.js'
import BaseController from './base_controller'

import store from 'javascripts/applicant/store'

// Connects to data-controller="vue--applicant"
export default class extends BaseController {
  createApp() {
    return new Vue({
      data: {
        offices: [],
        officeAreas: [],
        formOptions: {},
        companyUser: {}
      },
      store,
      components: {
        ApplicantIndex: () => import('javascripts/applicant/applicant_index.vue')
      },
      template: '<ApplicantIndex />',
      mounted: function () {
        let node = document.getElementById('setup');
        let setupObject = JSON.parse(node.getAttribute('data'))
        let searches = {
          status_group: 'scout_step',
          sub_status: '',
          serial: '',
          like_applicant_name: '',
          interview_date: '',
          like_office_name: '',
          employment_status_cd: '',
          gender_cd: '',
          age: '',
          introduce_cd: '',
          like_hr_person_name: ''
        }
        if (setupObject.searches) {
          for (let key in setupObject.searches) {
            searches[key] = setupObject.searches[key]
          }
        }
        this.$store.commit('setupStatusGroups', setupObject.status_groups)
        this.$store.commit('searchModule/commitObject', searches, { root: true })
        this.$store.commit('setupHasContract', setupObject.has_contract)
      },
    });
  }
}

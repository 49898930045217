import Vue from 'vue/dist/vue.esm.js'
import BaseController from './base_controller'

// Connects to data-controller="vue--office-area"
export default class extends BaseController {
  createApp() {
    return new Vue({
      components: {
        OfficeAreaIndex: () => import('javascripts/office_areas/index.vue')
      },
      template: '<OfficeAreaIndex />'
    });
  }
}

import VueBaseController from '../base_controller'

// Connects to data-controller="vue--modal--base"
export default class extends VueBaseController {
  static values = {
    // モーダルの開閉状態
    open: Boolean
  }

  /**
   * モーダルコンポーネントをインポートする(オーバーライド用)
   * * コントローラーの初期化時に呼び出され、戻り値が`this.componentPromise`にセットされる
   * @returns {Promise} モーダルコンポーネントのインポート結果
   */
  importModalComponent() {
    return Promise.resolve()
  }

  mountOnConnect = false

  initialize() {
    // モーダルコンポーネントのインポートを開始

    /** @member {Promise} componentPromise モーダルの非同期コンポーネント(`this.importModalComponent()`の戻り値) */
    this.componentPromise = this.importModalComponent()
  }

  async openValueChanged() {
    if (this.openValue) {
      // モーダルの表示状態がtrueになったら、Vueインスタンスを作成して`openModal`イベントを発火する
      await this.mountApp()
      this.dispatch('openModal')
    }
    else {
      // モーダルの表示状態がfalseになったら、Vueインスタンスを破棄して`closeModal`イベントを発火する
      this.destroyApp()
      this.dispatch('closeModal')
    }
  }
}

const reponseSuccess = 'success'
const reponseFailed = 'failed'

export default {
  state: {
    office: {}, //ModalWindowを開けたときにデータがセットされる
    formOptions: {}
  },
  mutations: {
    setupFormOptions(state, formOptions) {
      state.formOptions = formOptions
    },
    setObject(state, office) {
      state.office = office
    },
  },
  actions: {
    postOffice({ commit }, officeData) {
      return this.$axios.post('/api/offices/save', { offices_api: officeData }).then((response) => {
        commit('setObject', response.data)
        this.$notifyModule.successModalAlert('更新しました')
        return reponseSuccess
      }, (error) => {
        this.$notifyModule.errorModalAlert(error)
        return reponseFailed
      });
    },
    deleteObject({ commit }, officeData) {
      return this.$axios.post('/api/offices/delete', { offices_api: officeData }).then((response) => {
        this.$notifyModule.successAlert('削除しました')
        return reponseSuccess
      }, (error) => {
        this.$notifyModule.errorModalAlert(error)
        return reponseFailed
      });
    }
  },
  getters: {
    getFormOptions: (state) => {
      return state.formOptions
    },
    getIndustryCds: (state) => {
      return state.formOptions.industry_cds
    },
    getCarCommutingCds: (state) => {
      return state.formOptions.car_commuting_cds
    },
    getBusinessTripCds: (state) => {
      return state.formOptions.business_trip_cds
    },
    getSubjects: (state) => {
      return state.formOptions.subjects
    },
  },
}
import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import BaseController from './base_controller'

import store from 'javascripts/offices/store'

// Connects to data-controller="vue--modals--office-recruit-detail"
export default class extends BaseController {
  static values = {
    // 店舗データ
    office: Object,
    // モーダルの表示状態
    open: Boolean
  }

  importModalComponent() {
    return import('javascripts/offices/office_recruit_detail.vue')
  }

  createApp() {
    // Vuexのストアを作成し、店舗データの値をセットする
    const officeStore = new Vuex.Store(store);
    officeStore.commit('setObject', this.officeValue)

    // Vueインスタンスを作成
    return new Vue({
      store: officeStore,
      components: {
        OfficeRecruitDetail: () => this.componentPromise
      },
      methods: {
        // モーダルを閉じる時、`openValue`をfalseにする
        closeModal: () => {
          this.openValue = false
        }
      },
      render(h) {
        return h('OfficeRecruitDetail', {
          on: {
            closeModal: this.closeModal
          }
        })
      }
    })
  }
}

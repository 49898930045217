import { Controller } from "@hotwired/stimulus";
import Vue from 'vue/dist/vue.esm.js';

// Vuexの設定
import Vuex from 'vuex';
Vue.use(Vuex);

// Axiosの設定
import axios from 'axios';
axios.defaults.headers['X-CSRF-TOKEN'] = $('meta[name=csrf-token]').attr('content');
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
Vue.prototype.$axios = axios;
Vuex.Store.prototype.$axios = axios;

// DOMPurifyの設定
import DOMPurify from 'dompurify';
Vue.prototype.$sanitize = DOMPurify;

// notifyModuleの設定
import notifyModule from '@/entrypoints/notify_module';
Vue.prototype.$notifyModule = notifyModule;
Vuex.Store.prototype.$notifyModule = notifyModule;

// Connects to data-controller="vue--base"
export default class extends Controller {
  /** @member {boolean} mountOnConnect trueの場合、コントローラーが接続された時にVueインスタンスをマウントする */
  mountOnConnect = true;
  /** @member {boolean} destroyOnDisconnect trueの場合、コントローラーが切断される時にVueインスタンスを破棄する */
  destroyOnDisconnect = true;

  connect() {
    if (this.mountOnConnect) {
      this.mountApp();
    }
  }

  disconnect() {
    if (this.destroyOnDisconnect) {
      this.destroyApp();
    }
  }

  /**
   * Vueインスタンスを生成してコントローラー要素の末尾にマウントする
   */
  async mountApp() {
    this.app = await this.createApp();
    this.element.appendChild(this.app.$mount().$el);
  }

  /**
   * Vueインスタンスを破棄する
   */
  destroyApp() {
    if (this.app) {
      this.element.removeChild(this.app.$el);
      this.app.$destroy();
      this.app = null;
    }
  }

  /**
   * Vueインスタンスを生成する(オーバーライド用)
   * @returns {Vue | Promise<Vue>} Vueインスタンス
   */
  createApp() {
    return new Vue({});
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-reset"
export default class extends Controller {
  static targets = ['checkbox', 'select']

  reset() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
      checkbox.dispatchEvent(new Event('input'))
    })

    this.selectTargets.forEach(select => {
      select.selectedIndex = 0
      select.dispatchEvent(new Event('change'))
    })
  }
}

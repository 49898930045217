import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import BaseController from './base_controller'

import store from 'javascripts/recruits/store'

// Connects to data-controller="vue--recruit"
export default class extends BaseController {
  createApp() {
    return new Vue({
      store: new Vuex.Store(store),
      components: {
        RecruitIndex: () => import('javascripts/recruits/index.vue')
      },
      template: '<RecruitIndex />',
      mounted: function () {
        this.fetchOptionData()
        let searches = {
          office_area_cd: '',
          prefecture: '',
          office_like_name: ''
        }
        this.$store.commit('searchModule/commitObject', searches, { root: true })
      },
      methods: {
        fetchOptionData() {
          this.$axios.get('/api/recruits/index_options').then((response) => {
            this.$store.commit('searchModule/commitOptions', response.data.form_options, { root: true })
          }, (error) => {
            this.$notifyModule.errorAlert(error)
          });
        }
      },
    });
  }
}

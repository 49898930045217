import Vue from 'vue/dist/vue.esm.js'
import BaseController from './base_controller'

// Connects to data-controller="vue--modals--company-profile"
export default class extends BaseController {
  static values = {
    // モーダルの表示状態
    open: Boolean
  }

  importModalComponent() {
    return import('@/javascripts/companies/components/profile.vue')
  }

  async createApp() {
    // 企業情報モーダルコンポーネントを使ってVueインスタンスを生成
    const vm = new Vue(await this.componentPromise.then(module => module.default))

    // モーダル開閉時、結果を`openValue`に反映
    vm.$on('openProfileModal', open => {
      this.openValue = open
    })

    return vm
  }

  /**
   * 企業情報モーダルを開く
   */
  openModal() {
    this.openValue = true
  }
}

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="scout--search-modal"
export default class extends Controller {
  static values = {
    url: String,
    key: String,
    params: String
  }

  close() {
    const params = new URLSearchParams();

    if (this.hasParamsValue) {
      params.set(this.keyValue, this.paramsValue);
    }

    const modalUrl = `${this.urlValue}?${params}`;
    Turbo.visit(modalUrl, { frame: "modal" });
  }

  reload() {
    Turbo.visit(window.location.href, { frame: "parameters" })
  }
}

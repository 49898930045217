import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.headers['X-CSRF-TOKEN'] = $('meta[name=csrf-token]').attr('content')
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
Vue.use(Vuex)
import notifyModule from '@/entrypoints/notify_module'

const debug = process.env.NODE_ENV !== 'production'

const reponseSuccess = 'success'
const reponseFailed = 'failed'

export default new Vuex.Store({
  state: {
    Object: {}, //ModalWindowを開けたときにデータがセットされる
    ObjectLists: []
  },
  mutations: {
    setupObjects(state, ObjectLists) { state.ObjectLists = ObjectLists },
    setObject(state, Object) { state.Object = Object },
    dropObject(state){ state.Object = {} },
    commitObject(state, Object) {
      const objectIndex = state.ObjectLists.findIndex(element => element.code === Object.code)
      state.Object = Object
      if(objectIndex==-1){ //新規の場合は末尾に追加
        state.ObjectLists.push(Object)
      } else {
        state.ObjectLists.splice(objectIndex, 1, Object)
      }
    },
    removeObject(state, ObjectCode) {
      const newObjectLists = state.ObjectLists.filter(element => element.code !== ObjectCode)
      state.ObjectLists = newObjectLists
    }
  },
  actions: {
    loadObjects({commit}){
      return axios.get('/message_templates/list').then((response) => {
        commit('setupObjects', response.data.message_templates)
        return reponseSuccess
      }, (error) => {
        notifyModule.errorAlert(error)
        return reponseFailed
      });
    },
    postObject({ commit }, postData){
      return axios.post('/message_templates/save', { message_templates: postData } ).then((response) => {
        commit('commitObject', response.data.message_template);
        notifyModule.successAlert('更新しました')
        return reponseSuccess
      }, (error) => {
        notifyModule.errorModalAlert(error)
        return reponseFailed
      });
    },
    deleteObject({ commit }, postData){
      return axios.post('/message_templates/delete', { message_templates: postData } ).then((response) => {
        commit('removeObject', response.data.delete_code);
        notifyModule.successAlert('削除しました')
        return reponseSuccess
      }, (error) => {
        notifyModule.errorModalAlert(error)
        return reponseFailed
      });
    },
  }
})
import { Controller } from "@hotwired/stimulus"

// jQuery DataTablesの設定
const optionDataTable = {
  searching: true,
  iDisplayLength: 25,
  language: {
    sProcessing: '処理中...',
    sLengthMenu: '_MENU_ 件表示',
    sZeroRecords: '該当データはありません',
    sInfo: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
    sInfoEmpty: ' 0 件中 0 から 0 まで表示',
    sInfoFiltered: '(全 _MAX_ 件から抽出)',
    sInfoPostFix: '',
    sSearch: '検索：',
    sUrl: '',
    oPaginate: {
      sFirst: '先頭',
      sPrevious: '前',
      sNext: '次',
      sLast: '最終'
    }
  },
  order: [[1, 'asc']]
};

// Connects to data-controller="jquery-data-tables"
export default class extends Controller {
  static values = { enabled: Boolean }

  connect() {
    // enabledValueがfalseの場合のみ実行(二重起動防止)
    if (!this.enabledValue) {
      // jQuery dataTablesを起動
      // ※jQueryおよびjQuery DataTablesは`app/assets/javascripts/application.js`で読み込んでいる前提
      $(this.element).DataTable(optionDataTable);
      this.enabledValue = true;
    }
  }
}

export default {
  namespaced: true,
  state() {
    return {
      searchString: '',
      searchObject: {},
      searchOptions: {}
    }
  },
  mutations: {
    commitString(state, string) {
      state.searchString = string
    },
    commitObject(state, object) {
      state.searchObject = object
    },
    commitOptions(state, options) {
      state.searchOptions = options
    }
  },
  getters: {
    getSearchObject: (state) => {
      return state.searchObject
    },

  }
}
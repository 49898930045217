import Vue from 'vue/dist/vue.esm.js'

import VueController from './base_controller'
import store from 'javascripts/message_templates/store'

// Connects to data-controller="vue--message-template"
export default class extends VueController {
  createApp() {
    return new Vue({
      store,
      components: {
        MessageTemplate: () => import('javascripts/message_templates/index.vue')
      },
      template: '<MessageTemplate />'
    });
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
// コントローラー要素は<details>を想定
export default class extends Controller {
  // <details>要素を閉じる
  close(event) {
    // クリックされた要素が<details>要素内に含まれていない場合、かつ<details>要素が開いている場合
    if (!this.element.contains(event.target) && this.element.open) {
      // <details>要素を閉じる
      this.element.open = false
    }
  }
}

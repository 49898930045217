import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import BaseController from './base_controller'

import store from 'javascripts/offices/store'

// Connects to data-controller="vue--modals--office-detail-modal-vue"
// コントローラーが発火するイベント：
// * `post` - 店舗情報作成・更新時
// * `delete` - 店舗削除時
export default class extends BaseController {
  static values = {
    // 店舗データ
    office: Object,
    // 法人名
    companyName: String,
    // フォームオプション
    formOptions: Object,
    // モーダルの表示状態
    open: Boolean
  }

  importModalComponent() {
    return import('javascripts/offices/office_detail.vue')
  }

  createApp() {
    // 店舗データの整形
    const office = this.officeValue

    // Vuexのストアを作成し、店舗データの値をセットする
    const officeStore = new Vuex.Store(store);
    officeStore.commit('setObject', office)
    officeStore.commit('setupFormOptions', this.formOptionsValue)

    // Vueインスタンスを作成
    return new Vue({
      store: officeStore,
      components: {
        OfficeDetail: () => this.componentPromise
      },
      methods: {
        // モーダルを閉じる時、`openValue`をfalseにする
        closeModal: () => {
          this.openValue = false
        },
        // 店舗情報作成・更新時、`post`イベントを発火する
        post: () => {
          this.dispatch('post')
        },
        // 店舗削除時、`delete`イベントを発火する
        delete: () => {
          this.dispatch('delete')
        }
      },
      render(h) {
        return h('OfficeDetail', {
          on: {
            closeModal: this.closeModal,
            post: this.post,
            delete: this.delete
          }
        })
      }
    })
  }
}

import { Application } from '@hotwired/stimulus'
import Dialog from '@stimulus-components/dialog'
import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

const application = Application.start()
application.register('dialog', Dialog)
application.register('checkbox-select-all', CheckboxSelectAll)

if (import.meta.env.DEV) {
  // Configure Stimulus development experience
  application.debug = true
  window.Stimulus = application
}

export { application }

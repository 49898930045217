import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="auto-select"
export default class extends Controller {
  static values = {
    params: String,
    key: String,
    url: String,
    frameId: String
  }

  change(event) {
    /** @type {HTMLSelectElement} <select>要素 */
    const select = event.target
    /** 選択された値 */
    const value = select.value

    // パラメーターを生成
    const params = new URLSearchParams(this.hasParamsValue ? this.paramsValue : location.search)
    const key = this.hasKeyValue ? this.keyValue : select.name
    params.set(key, value)

    const newUrl = `${this.urlValue}?${params}`

    if (!this.hasFrameIdValue) {
      // frame未指定の場合はページ遷移
      Turbo.visit(newUrl)
    }
    else {
      // frameIdが指定されている場合はTurboFrameを更新
      Turbo.visit(newUrl, { frame: this.frameIdValue })

      // frame要素を取得
      const frame = document.getElementById(this.frameIdValue)

      if (frame) {
        // 更新が完了するまで選択を無効化
        select.disabled = true

        // TurboFrameの更新が完了したら選択を有効化
        frame.addEventListener("turbo:frame-load", () => {
          select.disabled = false
        }, { once: true })
      }
    }
  }
}

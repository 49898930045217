import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list"
// <turbo-frame>がコントローラー要素となる想定
export default class extends Controller {
  // リストを再読み込みする
  reload() {
    if (this.element.src) {
      // `src`が設定されている場合は再読み込み
      // SEE: https://turbo.hotwired.dev/reference/frames#functions
      this.element.reload()
    }
    else {
      // `src`が設定されていない場合は現在のURLで再読み込み
      this.element.src = window.location.href
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scout--profile-list"
export default class extends Controller {
  static values = { currentScoutPoint: Number }
  static targets = ['checkbox', 'counter', 'buttonArea', 'buttonAreaInsufficient']

  connect() {
    this.check()
  }

  check() {
    /** @type {number} 選択した候補者の数 */
    const checked = this.checkboxTargets.filter(checkbox => checkbox.checked).length
    /** 気になる枠数が足りているか */
    const sufficient = checked <= this.currentScoutPointValue
    this.counterTarget.innerHTML = checked

    // 選択状態かつ気になる枠数が足りている場合はボタンを表示
    this.buttonAreaTarget.hidden = !(checked && sufficient)

    // 選択状態かつ気になる枠数が足りていない場合は不足時のボタンを表示
    this.buttonAreaInsufficientTarget.hidden = !(checked && !sufficient)
  }
}
